* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: elaineSans-SemiBold ,elaineSans-Regular;;
 
}

:-webkit-x-scrollbar{
  display: none;
}


.title {
  margin-top: 50px;
  font-family: elaineSans-SemiBold;
  font-size: 45px;
}

.hilal {
  width: 100%;
  display: block;
  padding: 28px 0px 0px 0px;
  font-size: 35px;
}

.hilal span {
  color: red;
}

.hilal p {
  font-size: 12.3px;
  color: green;
  font-weight: 700;
}

.name-heading {
  padding-top: 10px;
  font-family: elaineSans-SemiBold;
  font-size: 14px;
  font-weight: 600;
}

.member {
  font-size: 14px;

}
.member b {
  color: red;
}



.council-logo-left {
  width: 50%;
  
  display: block;
  /* margin-top: rem; */
  text-align: center;
}
.council-logo-left img{
    width: 250px;
    height: 250px;
    margin: auto;
}

/* .main-council{
  display: flex;
} */



.council-logo-right {
  width: 50%;
  
  display: block;
  margin-top: 3.5rem;
  text-align: center;
}


.council-logo-right img{
    width: 250px;
    height: 250px;
    margin: auto;
}



.council-logo h4 {
  text-align: center;
}








/* h2 {
  font-size: 45px;
} */


/* ====================== heading center====================== */
/* 
.Heading {
  border: 1px solid black;
  height: auto;
}

 .Heading span .text-and{
 text-transform: lowercase;
 font-size: 4rem;
}

.Heading h1 span {
  font-family: elaineSans-ExtraLight;
  transition: all 0.3s ease-in-out;
  font-size: 4rem;
  line-height: 20px;
  text-transform: uppercase;
  color: #d9dadb;
  margin-top: 30px;
}

.Heading h2 {
  font-family: elaineSans-SemiBold;
  font-size: 45px;
  font-weight: 500;
  line-height: 10px;
}

.home-heading1 {
  margin-top: 8rem;
}



@media screen  and (min-width:768px)  and (max-width:1200px) {
  

  .Heading h1 span {
   
    line-height: 10rem;
    text-transform: uppercase;
    color: #d9dadb;
    margin-top: 30px;
  }
  

  .Heading span .text-and{
    text-transform: uppercase;
    font-size: 4rem;
   
   }
   




} */





/* ==================================images================= */


.content-display {
  padding: 30px;
  width: 100%;
  height: 100%;
  top: 0;
 
}
.content-display:hover  {
  background-color:  rgba(208, 208, 219, 0.947);
  color: #fff;
}
.display-h2 {
  font-size: 2rem;
  color: #d9dadb;
  font-family: elaineSans-Regular;
}
.content-display h3 {
  color: #fff;
  font-family: elaineSans-Regular;
  font-weight: 100;
  font-size: 12px;
  text-transform: uppercase;
  line-height: 20px;
  margin-top: 1rem;
  margin-bottom: 25px;
}
.content-display h3:hover{
  color: white;
  
}

.content-display p a {
  text-decoration: none;
  background: #fff;
  padding: 8px 20px;
  font-size: 17px;
  margin-top: 20px;
  border-radius: 35px;
  color: #000;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.46);
  display: inline-block;
  overflow: visible;
  margin-bottom: 10px;
  text-shadow: none;
  font-weight: 400;
  transition: all 0.3s ease-in-out;
}
.content-display p a:hover{

  color: #fff;
  background: #000;


}


